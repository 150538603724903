import React from "react";
import "../../../components/styles/style.css";
import Form from "react-bootstrap/Form";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../../components/header";
import ImgLogin from "../../../assets/img/imgLogin.jpg";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { userRegister } from "../../../redux/actions/registerActions";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import Footer from "../../../components/footer";
import { useUserContext } from "../../../providers/useUserContext";
import { useMemo, useState, useEffect, Fragment, useRef } from "react";
import { userSignIn } from "../../../redux/actions/authActions";
import { useParams } from "../../../hooks/useParams";
import { useScrollbar } from "../../../hooks/useScrollbar";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";

import * as AttendenceMethodsServices from "../../../services/api/Attendence-methodsServices";
import * as AttendencePlataformsServices from "../../../services/api/Attendence-plataformsServices";
import * as InsurancesServices from "../../../services/api/InsurancesServices";
import * as ProfessionalsServices from "../../../services/api/ProfessionalsServices";
import * as SpecialitiesServices from "../../../services/api/SpecialitiesServices";
import { Checkbox as StyledCheckbox } from "../../../components/checkbox";
import { CircularProgress } from "../../../components/circularProgress";
import { editProfessionalSchema } from "../../../validations/edit-professional-schema";
import imgAp from "../../../assets/img/imgLogin.jpg";
import { v4 as uuid } from "uuid";

import EditIcon from "@material-ui/icons/Edit";
import FormGroup from "@material-ui/core/FormGroup";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import Select from "react-select";
import validator from "validator";
import { Link } from "@material-ui/core";

import { useFormik } from "formik";

import "../../../components/styles/professionalEditar.styles.css";

import Swal from "sweetalert2";
import * as PlansServices from '../../../services/api/PlansServices';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import axios from "axios";


const profFormation = [ 
  {
    label: "FONOAUDIOLOGIA",
    value: "FONOAUDIOLOGIA",
    name: "formation",
  },
  {
    label: "PSICOLOGIA",
    value: "PSICOLOGIA",
    name: "formation",
  },
  {
    label: "PEDAGOGIA",
    value: "PEDAGOGIA",
    name: "formation",
  },
  {
    label: "FISIOTERAPIA",
    value: "FISIOTERAPIA",
    name: "formation",
  },
];
const profTitle = [
  {
    label: "GRADUAÇÃO",
    value: "GRADUAÇÃO",
    name: "title",
  },
  {
    label: "ESPECIALIZAÇÃO",
    value: "ESPECIALIZAÇÃO",
    name: "title",
  },
  {
    label: "MESTRADO",
    value: "MESTRADO",
    name: "title",
  },
  {
    label: "DOUTORADO",
    value: "DOUTORADO",
    name: "title",
  },
  {
    label: "PÓS-DOUTORADO",
    value: "POS-DOUTORADO",
    name: "title",
  },
];
 
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = styled(MuiAlert)(spacing);

const steps = [
  {
    label: "Foto",
    description: ``,
  },
  {
    label: "Select campaign settings",
    description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: "Create an ad group",
    description:
      "An ad group contains one or more ads which target a shared set of keywords.",
  },
  {
    label: "Create an ad",
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  image: {
    backgroundImage: `url(${ImgLogin})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  form: {
    marginTop: theme.spacing(5),
    width: "90%",
  },
  paper: {
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(20, 10),
    "@media (max-width: 500px)": {
      padding: "0px 0px 0px 0px",
      margin: theme.spacing(5, 5),
    },
  },
  submit: {
    margin: theme.spacing(5, 0, 5, 0),
    borderRadius: "30px",
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Roboto",
    backgroundColor: "#54B5CF",
    padding: "5px 20px 5px 20px",
    color: "#F8F8FA",
    "&:hover": {
      backgroundColor: "#0facc4",
      textDecoration: "none",
      color: "#fff",
    },
  },
  titulo: {
    color: "#54B5CF",
    fontFamily: "Montserrat",
    fontSize: "35px",
    fontWeight: 800,
    marginBottom: 10,
    "@media (max-width: 500px)": {
      fontSize: "24px",
    },
  },
  textLink: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "18px",
    color: "#54B5CF",
    textDecoration: "underline",
    "&:hover": {
      color: "#0facc4",
    },
  },
  gridLink: {
    display: "flex",
    "@media (max-width: 500px)": {
      display: "grid",
    },
  },
  input: {
    borderWidth: "0 0 1px 0",
    borderRadius: 0,
    padding: 0,
    backgroundColor: "#F8F8FA",
    "&::placeholder": {
      fontSize: 18,
      color: "#C4C4C4",
    },
    " &:focus": {
      backgroundColor: "#F8F8FA",
      boxShadow: "0 0 0px #fff",
      borderColor: "#0facc4",
    },
  },
  link: {
    marginTop: "50px",
    "@media (max-width: 500px)": {
      marginTop: "0",
    },
  },
  textInput: {
    fontSize: 23,
    color: "#54B5CF",
    fontFamily: "Roboto",
    width: 400,
    margin: "20px 0 -5px 0",
  },
}));

export default function Registro() {
  const { routeParams } = useParams();
  const { hideScrollBar, showScrollBar } = useScrollbar();

  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useUserContext();
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [fullWidth, setFullWidth] = React.useState(true);

  const professionalId = useMemo(() => routeParams?.pop(), [routeParams]);
  const dialogRef = useRef(null);

  const [form, setForm] = useState({});

  const [selectTitle, setSelectTitle] = useState(null);
  const [selectFormation, setSelectFormation] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [professionalSpecialities, setProfessionalSpecialities] = useState([]);

  const [image, setImage] = useState("");
  const [imageForm, setImageForm] = useState("");

  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });

  const [profile, setProfile] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
 
  // eslint-disable-next-line no-unused-vars
  const [attendenceMethodsCheckbox, setAttendenceMethodsCheckbox] = useState(
    []
  );

// eslint-disable-next-line no-unused-vars
  const [clinicAddresses, setClinicAddresses] = useState([]);
 // textInput must be declared here so the ref can refer to it 
 

 const [cpfCnpj, setCpfCnpj] = useState("");
 const [mask, setMask] = useState("");

 const [fileUrl, setFileUrl] = useState("");

 function handleAddClinicClick() {
  let addConsulorio = document.querySelector(`#addConsulorio`);
  addConsulorio.click()
 }
 
  useEffect(() => {
    (async () => {
      const resp = await ProfessionalsServices.getProfile(); 
       if(!resp.data?.picture){
          setOpen(true);
        }
        else{
          if (user) {
              history.push('/admin/profissional/perfil')
          }
        }
      setProfile(resp.data);
    })();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };
 

//envio do formulario
  const handleOnSubmit = async (data) => { 
   /*  console.log('asdas') 
    console.log(imageForm) */
    if (user) {
      history.push('/admin/profissional/perfil')
    }
    const attendanceMethods =
      values?.attendanceMethods?.map((el) => {
        return {
          attendenceMethodId: el.attendenceMethodId || el.attendenceMethod.id,
          professionalId: professionalId,
          period: el?.period || [],
          value: el.value,
          schedule: el.schedule,
        };
      }) || [];

    const profileData = { 
      clinicAddresses: data.clinicAddresses,
      crfa: data.crfa,
      description: data.description,
      email: data.email,
      formation: data.formation,
      insurances: data.insurance,
      methods: [],
      name: data.name,
      phone: data.phone,
      picture: fileUrl || image || data.picture,
      title: data.title,
      whatsapp: data.whatsapp,
      platforms: data.platforms,
      speacilities: data.speciality,
      cpf_cnpj: cpfCnpj,
    };
 
    const qualifications =
      values?.qualifications?.map((q) => {
        return {
          specialityId: q.specialityId,
          professionalId,
          professionalAttendenceMethodId: q?.professionalAttendenceMethodId,
          type: q?.type,
        };
      }) || [];

    hideScrollBar();
    dialogRef.current?.showModal();
        setIsSubmitting(true);

    const formData = new FormData();
    formData.append("fileToUpload", imageForm);
    console.log(imageForm) 
    if (imageForm !== "") { 
      console.log('upload file'); 
      const endpoint = "https://files.minhafono.com.br/upload.php";
      axios.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        const uploadedFileUrl = res.data.file_url;
        setFileUrl(uploadedFileUrl); // Atualiza o estado com o URL do arquivo
    
        Swal.fire({
          icon: 'success',
          title: 'Foto de capa atualizada!',
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao enviar a imagem!',
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      });
    }

    if (image === "") {
      console.log('Necessário foto') 
    }

    await ProfessionalsServices.updateProfile(profileData).then(
      () => { 
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false, 
          timer: 5000,
          timerProgressBar: true,
          didOpen: async (toast) => { 
          },
          willClose: async () => { 
          },
        });
  
        Toast.fire({
          icon: 'success',
          title: 'Cadastro concluído com sucesso!',
        });
 
      },
      () => { 
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false, 
          timer: 5000,
          timerProgressBar: true,
          didOpen: async (toast) => { 
          },
          willClose: async () => { 
          },
        });
  
        Toast.fire({
          icon: 'error',
          title: 'Existem campos nulos',
        });
      }
    );

    attendanceMethods?.forEach(async (atd) => {
      await ProfessionalsServices.postProfessionalAttendenceMethods(atd).then(
        () => {
          qualifications?.forEach(async () => {});
        }
      );
    });

    setIsSubmitting(false);
    dialogRef.current?.close();
        showScrollBar();
  };

  const {
    values,
    handleChange,
    handleSubmit: onSubmit,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: {
      ...form,
      insurances: [],
    },
    enableReinitialize: true,
    validationSchema: editProfessionalSchema,
    onSubmit: handleOnSubmit,
  }); 
 
  // Remove formatting and re-format after.
  // Necessary to resolve bugs with formatting and auto-complete
  const ajusta = v => {
      const digitos = !v ? '' : v.replace(/[^\d]/g, '');
      if (!digitos || digitos.length < 10) return v;
      const corte = digitos.length === 10 ? 6 : 7;
      const max = digitos.length > 11 ? 11 : digitos.length;
      return `(${digitos.substring(0, 2)}) ${digitos.substring(2, corte)}-${digitos.substring(corte, max)}`;
  }

  const maskBuilder = v => {
      if (!v || v.length === 0) return '';
      const a = ajusta(v);
      return (a.length >= 6 && a[5] === '9') ? '(99) 99999-9999' : '(99) 9999-9999';
  }

  const handleChangeSelect = (e) => {  
    e.name === 'title' && setSelectTitle(e.value)
    e.name === 'formation' && setSelectFormation(e.value)
    setFieldValue(e.name, e.value, ajusta(e.value));
  };

  const handleChangeInsurances = (e, label) => {
    const { checked, value } = e.target;
    if (checked) {
      setFieldValue("insurancesCheck", [
        ...values.insurancesCheck,
        {
          insurance: {
            id: value,
            name: label,
          },
          insuranceId: value,
          professionalId,
        },
      ]);
    } else {
      setFieldValue(
        "insurancesCheck",
        values.insurancesCheck.filter((insu) => insu.insurance.id !== value)
      );
    }
    handleChange(e);
  };
  const handleChangeSpecialities = (e, label) => {
    const { checked, value } = e.target;
    if (checked) {
      setFieldValue("specialitiesCheck", [
        ...values.specialitiesCheck,
        {
          speciality: {
            id: value,
            name: label,
          },
          specialityId: value,
          professionalId,
        },
      ]);
    } else {
      setFieldValue(
        "specialitiesCheck",
        values.specialitiesCheck.filter((insu) => insu.speciality.id !== value)
      );
    }
    handleChange(e);
  };
  const handleChangeAttendance = (e, label) => {
    const { checked, value } = e.target;
    if (checked) {
      setFieldValue("attendanceMethods", [
        ...values.attendanceMethods,
        {
          attendenceMethod: {
            id: value,
            name: label,
          },
          attendenceMethodId: value,
          period: [],
          professionalId,
          schedule: "",
          value: "",
        },
      ]);

      setFieldValue("qualifications", [
        ...values.qualifications,
        {
          specialityId: [],
          professionalId,
          professionalAttendenceMethodId: value,
        },
      ]);

      setFieldValue("professionalSpecialities", [
        ...values.professionalSpecialities,
        {
          specialityId: [],
          professionalId,
          professionalAttendenceMethodId: value,
        },
      ]);
    } else {
      setFieldValue(
        "attendanceMethods",
        values.attendanceMethods.filter(
          (atd) => atd.attendenceMethod.id !== value
        )
      );
      setFieldValue(
        "insurances",
        values.insurances.filter((insu) => insu.insurance.id !== value)
      );

      setFieldValue(
        "qualifications",
        values.qualifications.filter(
          (qualy) => qualy.professionalAttendenceMethodId !== value
        )
      );

      setFieldValue(
        "professionalSpecialities",
        values.professionalSpecialities.filter(
          (qualy) => qualy.professionalAttendenceMethodId !== value
        )
      );
    }

    handleChange(e);
  };

  const handleFile = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setImageForm(e.target.files[0]);
  
    const formData = new FormData();
    formData.append("fileToUpload", e.target.files[0]);
  
    const endpoint = "https://files.minhafono.com.br/upload.php";
    axios.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      const uploadedFileUrl = res.data.file_url;
      setFileUrl(uploadedFileUrl); // Atualiza o estado com o URL do arquivo
  
      Swal.fire({
        icon: 'success',
        title: 'Foto de capa atualizada!',
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }).catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao enviar a imagem!',
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    });
  };
  

  const validateEmail = (e) => {
    var email = e.target.value;

    if (!validator.isEmail(email)) {
      setFieldError("email", "E-mail inválido.");
    } else {
      setFieldError("email", undefined);
    }

    handleChange(e);
  };
 
  let atmIds = [];

  useEffect(() => {
    (async () => {
      try {
        hideScrollBar();
        dialogRef.current?.showModal();
        const resp = await ProfessionalsServices.getProfile();
        const respAtt = await ProfessionalsServices.getAttendenceMethods();
        const professionalSpec =
          await ProfessionalsServices.getProfessionalSpecialities(resp.data.id);
        setProfessionalSpecialities(professionalSpec.data);
        setAttendenceMethod(respAtt.data); 
        console.log(resp.data)
        setForm({
          ...resp.data,
          attendanceMethods: resp.data.professionalAttendenceMethods.map(
            (el) => {
              return {
                attendenceMethod: el.attendenceMethod,
                attendenceMethodId: el.attendenceMethod.id,
                period: JSON.parse(el?.period) || [],
                professionalId,
                schedule: el.schedule || "",
                value: el.value || "",
              };
            }
          ),
          insurancesCheck: resp.data.professionalInsurances.map((el) => {
            return {
              insurance: el.insurance,
              insuranceId: el.insurance.id,
              professionalId,
            };
          }),
          specialitiesCheck: resp.data.professionalSpecialities.map((el) => {
            return {
              speciality: el.speciality,
              specialityId: el.id,
              professionalId,
            };
          }),
          speciality: resp.data.professionalSpecialities.map(
            (atm) => atm.speciality.id
          ),
          attendance: resp.data.professionalAttendenceMethods.map(
            (atm) => atm.attendenceMethod.id
          ),
          insurance: resp.data.professionalInsurances.map(
            (insu) => insu.insurance.id
          ),
        });
        setImage(resp.data.picture);
        setClinicAddresses(resp.data.clinicAddresses);

        resp.data.professionalAttendenceMethods.forEach((atm) =>
          atmIds.push(atm.attendenceMethod.id)
        );
        setAttendenceMethodsCheckbox(atmIds);
        setTimeout(() => { 
         handleAddClinicClick();
        }, 1500);
        
       // handleClick();
      } catch {  
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false, 
          timer: 5000,
          timerProgressBar: true,
          didOpen: async (toast) => { 
          },
          willClose: async () => { 
          },
        });
  
       /*  Toast.fire({
          icon: 'error',
          title: 'Erro ao retornar dados!',
        }); */
      } finally {
        dialogRef.current?.close();
        showScrollBar();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addClinicHandler = async () => {
    hideScrollBar(); 
   dialogRef.current?.showModal();

    await ProfessionalsServices.addClinic(form.id).then(
      () => {
       // console.log(values.clinicAddresses);
        setFieldValue("clinicAddresses", [
          ...values?.clinicAddresses,
          {
            id: uuid(),
            updatedAt: "",
            deletedAt: null,
            version: 1,
            clinic_name: "",
            clinic_contact: "",
            street: "",
            number: "",
            zipcode: "",
            city: "",
            state: "",
            district: "",
            complement: null,
          },
        ]);
      },
      () => {}
    );

    dialogRef.current?.close();
    showScrollBar();
  };

  const removeClinicHandler = async (id) => {
    hideScrollBar();
    dialogRef.current?.showModal();
    await ProfessionalsServices.removeClinic(id).then(
      () => {
        setFieldValue(
          "clinicAddresses",
          values.clinicAddresses.filter((c) => c.id !== id)
        );
      },
      () => {}
    );
    dialogRef.current?.close();
    showScrollBar();
  };

  const [specialities, setSpecialities] = useState([]);
  useEffect(() => {
    (async () => {
      const resp = await SpecialitiesServices.get();
      setSpecialities(resp.data);
    })();
  }, []);

  const [attendenceMethod, setAttendenceMethod] = useState([]);

  const [insurances, setInsurances] = useState([]);
  useEffect(() => {
    (async () => {
      const resp = await InsurancesServices.get();
      setInsurances(resp.data);
    })();
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [attendencePlataforms, setAttendencePlataforms] = useState([]);
  useEffect(() => {
    (async () => {
      const resp = await AttendencePlataformsServices.get();
      setAttendencePlataforms(resp.data);
    })();
  }, []);

  const [attendenceMethods, setAttendenceMethods] = useState([]);
  useEffect(() => {
    (async () => {
      const resp = await AttendenceMethodsServices.get();
      setAttendenceMethods(resp.data);
    })();
  }, []);

  const checkCEP = (e, key) => {
    const cep = e.target.value.replace(/\D/g, "");
    if (!e.target.value) return;
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .catch((err) => console.log(err))
      .then((res) => res.json())
      .then(
        (addressCEP) => {
          setFieldValue(`clinicAddresses[${key}].state`, addressCEP.uf);
          setFieldValue(`clinicAddresses[${key}].district`, addressCEP.bairro);
          setFieldValue(
            `clinicAddresses[${key}].street`,
            addressCEP.logradouro
          );
          setFieldValue(`clinicAddresses[${key}].city`, addressCEP.localidade);
        },
        () => {}
      );
  };

  const {
    input,
    linkEditar,
    titulo,
    newGrid,
    menu,
    perfil,
    page,
    imagem,
    submit,
    subtitulo,
    typographyTitulo,
    typographySubtitulo  } = classes;

  useEffect(() => {
    const body = {
      plans_id: '021f5207-9579-43b9-af00-90a0a31bd2er',
      isActive: true
      }
     PlansServices.post(body); 
  }, [values]);


  /* 
  
  const getInputBorderColor = (fieldName) => {
    // Determine the border color based on the validation status
    return Formik?.touched[fieldName] && Formik?.errors[fieldName]
      ? 'red' // Invalid input, set border color to red
      : 'green'; // Valid input, set border color to green
  }; */
  const customStyles = ({
   
    control: styles => ({
      ...styles,
      border:  `1px solid red`,
      "&:hover": {
        borderColor: "black"
      }
    }) 
  });

  const customStylesDefault = ({
   
    control: styles => ({
      ...styles,
      border:  `1px solid black`,
      "&:hover": {
        borderColor: "black"
      }
    }) 
  });

  const [emailExists, setEmailExists] = useState(false);

  const checkEmailExists = async (email) => {
    try {  
      const result = await ProfessionalsServices.getByMail(email);    
      return result.data.length;
    } catch (error) {
      console.error('Error checking email:', error);
      return 0;
    }
  };

  const handleBlurEmail = async (e) => {
    const { name, value } = e.target; 
    if (name === 'email') { 
      const exists = await checkEmailExists(value);
      setEmailExists(exists);
    }
  };
  
  return (
    <div style={{ backgroundColor: "#F8F8FA" }}>
      <Header />
      <Grid container component="main" style={{ marginBottom: "-5%" }}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={6} className={classes.image}></Grid>
        <Grid item xs={12} sm={9} md={6} elevation={6} square>
          <div className={classes.paper}>
            <Typography className={classes.titulo}>
              REGISTRO DO PROFISSIONAL
            </Typography>

            <Formik
              initialValues={{
                email: "",
                password: "",
                conselho: "",
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Preencha um email válido")
                  .max(255)
                  .required("Preencha um email válido"),
                password: Yup.string()
                  .max(255)
                  .required("Preencha uma senha válida")
                  .matches(
                    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{6,}$/,
                    "Mínimo de 6 caracteres, obrigatório ao menos uma letra maiuscula, um número e um caracter especial."
                  ),
                confirmPassword: Yup.string().oneOf(
                  [Yup.ref("password"), null],
                  "As senhas devem corresponder"
                ),
                name: Yup.string()
                  .max(255)
                  .required("Preencha com um nome válido"),
                crfa: Yup.string()
                  .max(255)
                  .required("Preencha com o número de conselho de classe"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  
                  await dispatch(
                    userRegister({
                      email: values.email,
                      password: values.password,
                      confirmPassword: values.confirmPassword,
                      name: values.name,
                      crfa: values.crfa,
                    })
                  );

                  await dispatch(
                    userSignIn({
                      email: values.email,
                      password: values.password,
                    })
                  );

                  setStatus({ success: true });
                  setSubmitting(true);
                  window.location.reload();
                  
                } catch (error) {
                  var message = error.message || "Ocorreu um erro inexperado";
                  if(error.status ==401){
                    message = "Falha na autenticação";
                  }
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                success,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert mt={2} mb={1} severity="warning">
                      Falha ao enviar informações.
                    </Alert>
                  )}
                  {isSubmitting && (
                    <Alert mt={2} mb={1} severity="success">
                      Informações complementares
                    </Alert>
                  )}
                  <p
                    className={classes.textInput}
                    style={{
                      color:
                        touched.name && values.name && !errors.name
                          ? "green"
                          : "",
                    }}
                  >
                    NOME
                  </p>

                  <TextField
                    type="text"
                    name="name"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.input}
                    my={2}
                    fullWidth
                    required
                  />
                  <p
                    className={classes.textInput}
                    style={{
                      color:
                        touched.email && values.email && !errors.email
                          ? "green"
                          : "",
                    }}
                  >
                    E-MAIL
                  </p>

                  <TextField
                    type="email"
                    name="email"
                    value={values.email}
                    error={Boolean(touched.email && (errors.email || emailExists))} 
                    helperText={
                      touched.email && 
                      (errors.email || (emailExists > 0 ? 'Este email já está cadastrado.' : ''))
                    }
                    onBlur={handleBlurEmail}
                    onChange={handleChange}
                    className={classes.input}
                    autoComplete="off"
                    my={2}
                    fullWidth
                  /> 
                  <p
                    className={classes.textInput}
                    style={{
                      color:
                        touched.conselho && values.conselho && !errors.conselho
                          ? "green"
                          : "",
                    }}
                  >
                    NÚMERO DE CONSELHO DE CLASSE
                  </p>

                  <TextField
                    type="text"
                    name="crfa"
                    value={values.crfa}
                    error={Boolean(touched.crfa && errors.crfa)}
                    helperText={touched.crfa && errors.crfa}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.input}
                    autoComplete="off"
                    my={2}
                    fullWidth
                  />

                  <p
                    className={classes.textInput}
                    style={{
                      color:
                        touched.password && values.password && !errors.password
                          ? "green"
                          : "",
                    }}
                  >
                    SENHA<br></br>
                  </p>

                  <TextField
                    type="password"
                    name="password"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    helperText={
                      "Mínimo de 6 caracteres, obrigatório ao menos uma letra maiuscula, um número e um caracter especial."
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.input}
                    fullWidth
                    inputProps={{
                      color: "green",
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    mt={2}
                    mb={8}
                  />
                  {/*  border-bottom: 3px solid green; */}
                  <p
                    className={classes.textInput}
                    style={{
                      color:
                        touched.confirmPassword &&
                        values.confirmPassword &&
                        !errors.confirmPassword
                          ? "green"
                          : "",
                    }}
                  >
                    CONFIRMAR SENHA
                  </p>

                  <TextField
                    type="password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.input}
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    fullWidth
                    mt={2}
                    mb={8}
                  />
                  <Grid className={classes.gridLink}>
                    <Grid item md={4}>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        className={classes.submit}
                      >
                        Continuar
                      </Button>
                     {/*  <Button onClick={handleClickOpen}>Open modal</Button> */}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Perfil profissional"}</DialogTitle> 
            <DialogContent>
            <small>* Dados obrigatórios</small>
              <DialogContentText id="alert-dialog-slide-description">
                <Formik
                  validationSchema={Yup.object().shape({  
                    formation: Yup.object().shape({ 
                          value: Yup.string().required("Required")
                      }),
                      title: Yup.object().shape({ 
                          value: Yup.string().required("Required")
                      })
                  })}
                >
                  {({ errors, touched, handleBlur }) => (
                    <form onSubmit={onSubmit}>
                                         <Grid>
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            style={{ textAlign: "center", marginTop: "35px" }}
                          >
                            {image ? (
                              <img
                                className={imagem}
                                src={image}
                                alt="Imagem Professional"
                                width="150"
                                height="150"
                              />
                            ) : (
                              <img
                                src={imgAp}
                                className={imagem}
                                alt="Imagem"
                                width="150"
                                height="150"
                              />
                            )}
                            <br />
                            <br />
                            Editar Foto *
                            <input 
                              accept="image/*"
                              className={input}
                              name="picture"
                              onChange={(e) => handleFile(e)}
                              id="contained-button-file"
                              type="file"
                              required 
                            />
                            <br />
                           {/*  <label htmlFor="contained-button-file">
                              <Link
                                color="primary"
                                component="span"
                                className={linkEditar}
                                style={{ cursor: "pointer" }}
                              >
                                <EditIcon /> Editar Foto *
                              </Link>
                            </label> */}
                          </Grid>

                          <Grid item xs={12} sm={9} style={{ marginTop: 10 }}>
                          <Typography className={typographySubtitulo}>
                              NOME COMPLETO *
                            </Typography>
                            <TextField
                              fullWidth
                              id="name"
                              type="text"
                              value={values?.name}
                              name="name" 
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                              required
                            /> 

                            <Typography className={typographySubtitulo}>
                              NÚMERO DE CONSELHO DE CLASSE *
                            </Typography>
                            <TextField
                              fullWidth
                              id="crfa"
                              type="text"
                              value={values?.crfa}
                              error={Boolean(touched.crfa && errors.crfa)}
                              helperText={touched.crfa && errors.crfa}
                              name="crfa"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                              required
                            />

                            <Typography className={typographySubtitulo}>
                              CPF/CNPJ *
                            </Typography>
                            <CpfCnpj
                            fullWidth
                              id="cpf_cnpj"
                              type="text" 
                              name="cpf_cnpj"
                              error={Boolean(touched.cpf_cnpj && errors.cpf_cnpj)}
                              helperText={touched.cpf_cnpj && errors.cpf_cnpj} 
                              value={cpfCnpj}
                              onChange={(event, type) => {
                                setCpfCnpj(event.target.value);
                                setMask(type === "CPF");
                              }}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                              style={{width: '100%'}}
                              required
                            />
                           {/*  <TextField
                              fullWidth
                              id="cpf_cnpj"
                              type="text"
                              value={values?.cpf_cnpj}
                              error={Boolean(touched.cpf_cnpj && errors.cpf_cnpj)}
                              helperText={touched.cpf_cnpj && errors.cpf_cnpj}
                              name="cpf_cnpj"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                              required
                            /> */}
                          </Grid>
                        </Grid>

                        {/* Curriculo */}
                        <br />
                        <Typography className={typographyTitulo}>
                          CURRÍCULO
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            <Typography className={typographySubtitulo}>
                              FORMAÇÃO ACADÊMICA *
                            </Typography>
                            <Select
                            styles={selectFormation ? customStylesDefault : customStyles}
                              placeholder="Selecione"
                              id="formation"
                              name="formation"
                              options={profFormation}
                              isSearchable={false}
                              onChange={handleChangeSelect}
                              error={Boolean(touched.formation && errors.formation)}
                              helperText={touched.formation && errors.formation}  
                              required
                              value={profFormation.find(
                                (profFormation) =>
                                profFormation.value === values?.formation
                              )}
                            />
                            <Typography className={typographySubtitulo}>
                              TITULAÇÃO MÁXIMA *
                            </Typography>
                            <Select
                              styles={selectTitle ? customStylesDefault : customStyles}
                              placeholder="Selecione"
                              id="title"
                              name="title"
                              options={profTitle}
                              isSearchable={false}
                              onChange={handleChangeSelect}
                              value={profTitle.find(
                                (profTitle) => profTitle.value === values?.title
                              )}
                              required
                            />
                            <Typography className={typographySubtitulo}>
                              INFORMAÇÕES ADICIONAIS
                            </Typography>
                            <TextField
                              multiline={true}
                              maxRows={5}
                              inputProps={{ maxLength: 500 }}
                              fullWidth
                              id="description"
                              name="description"
                              value={values?.description}
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                            />
                            <Grid container spacing={3}></Grid>
                          </Grid>
                        </Grid> <br></br> <br></br>  
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Typography className={typographySubtitulo}>
                              TELEFONE
                            </Typography> 
                            <InputMask 
                              maskPlaceholder="(00) 0000-0000"
                              mask="(99) 9999-9999"
                              fullWidth
                              id="phone"
                              name="phone"
                              label="(00) 0000-0000"
                              value={values?.phone}
                              type="tel"
                              onChange={handleChange}
                              InputLabelProps={{
                                style: { fontSize: "12px", marginTop: '0px !important' },
                                
                              }}
                              required
                            >
                              {() => (
                                <TextField
                                  name="phone"
                                  style={{ marginTop: "16px", width: "100%" }}
                                  required
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography className={typographySubtitulo}>
                              WHATSAPP *
                            </Typography>
                            <InputMask
                              placeholder="(00) 00000-0000"
                              mask="(99) 99999-9999"
                              fullWidth
                              id="whatsapp"
                              name="whatsapp"
                              value={values?.whatsapp}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="tel" 
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                              required
                            >
                              {() => (
                                <TextField
                                  name="whatsapp"
                                  style={{ marginTop: "16px", width: "100%" }}  
                                  required
                                />
                              )}
                            </InputMask> 
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Typography className={typographySubtitulo}> 
                              <p 
                                style={{
                                  color:
                                    touched.email && values.email && !errors.email
                                      ? "green"
                                      : "",
                                }}
                              >
                                E-MAIL PROFISSIONAL *
                              </p>
                            </Typography>
                            <TextField
                              placeholder="seuemail@email.com"
                              fullWidth
                              type="email"
                              name="email" 
                              value={values?.email} 
                              onBlur={handleBlur}
                              onChange={handleChange}

                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                              required
                            /> 
                          </Grid>
                        </Grid>

                        {/* Antedimento */}

                        <Typography className={typographyTitulo}>
                          ATENDIMENTO
                        </Typography>

                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            <Typography className={typographySubtitulo}>
                              QUERO ATENDER
                            </Typography>
                            <Typography className={subtitulo}>
                              Selecione a opção de como gostaria de atender os
                              seus pacientes
                            </Typography>
                          </Grid>
                          {attendenceMethods.map((data) => (
                            <Grid item key={data.id} xs={12} sm={3}>
                              <FormGroup>
                                <StyledCheckbox
                                  checked={values?.attendance?.includes(
                                    data.id
                                  )}
                                  value={data.id}
                                  onChange={(e) =>
                                    handleChangeAttendance(e, data.name)
                                  }
                                  name="attendance"
                                  label={data.name}
                                  id={data.id} 
                                />
                              </FormGroup>
                            </Grid>
                          ))}
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            <Typography className={typographyTitulo}>
                              INFORMAÇÕES DO CONSULTÓRIO
                            </Typography>
                          </Grid> 
                          {values?.clinicAddresses?.map((data, key) => (
                            <Fragment key={data.id}>
                              <Grid
                                key={data.id}
                                container
                                spacing={3}
                                style={{
                                  borderStyle: "outset",
                                  borderColor: "#54B5CF",
                                  BorderRadius: "5px",
                                  borderRadius: "10px",
                                  margin: "10px 0",
                                  paddingBottom: "30px",
                                }}
                              >
                                <Grid item md={6} xs={6}>
                                  <Typography className={typographySubtitulo}>
                                    NOME DO CONSULTÓRIO *
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    id={`clinicAddresses[${key}].clinic_name`}
                                    name={`clinicAddresses[${key}].clinic_name`}
                                    value={
                                      values?.clinicAddresses[key]?.clinic_name
                                    }
                                    onChange={handleChange}
                                    InputLabelProps={{
                                      style: { fontSize: "12px" },
                                    }}
                                    required
                                  />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <Typography className={typographySubtitulo}>
                                    CONTATO CONSULTÓRIO *
                                  </Typography>
                                  <InputMask
                                    mask={maskBuilder(
                                      values?.clinicAddresses[key]
                                        ?.clinic_contact
                                    )}
                                    fullWidth
                                    id={`clinicAddresses[${key}].clinic_contact`}
                                    name={`clinicAddresses[${key}].clinic_contact`}
                                    value={
                                      values?.clinicAddresses[key]
                                        ?.clinic_contact
                                    }
                                    onChange={handleChange}
                                    InputLabelProps={{
                                      style: { fontSize: "12px" },
                                    }}
                                    required
                                  >
                                    {() => (
                                      <TextField
                                        name={`clinicAddresses[${key}].clinic_contact`}
                                        value={
                                          values?.clinicAddresses[key]
                                            ?.clinic_contact
                                        }
                                        onChange={handleChange}
                                        style={{ width: "100%" }}
                                        required
                                      />
                                    )}
                                  </InputMask>
                                </Grid>
                                <Grid item md={2} xs={12}>
                                  <Typography className={typographySubtitulo}>
                                    CEP *
                                  </Typography>
                                  <InputMask
                                    mask="99999-999"
                                    fullWidth
                                    id={`clinicAddresses[${key}].zipcode`}
                                    name={`clinicAddresses[${key}].zipcode`}
                                    value={
                                      values?.clinicAddresses[key]?.zipcode
                                    }
                                    onBlur={(e) => checkCEP(e, key)}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                      style: { fontSize: "12px" },
                                    }}
                                    
                                    required
                                  >
                                    {() => (
                                      <TextField
                                        name={`clinicAddresses[${key}].zipcode`}
                                        value={
                                          values?.clinicAddresses[key]?.zipcode
                                        }
                                        error={Boolean(touched.zipcode && errors.zipcode)}
                                        helperText={touched.zipcode && errors.zipcode} 
                                        onChange={handleChange}
                                        style={{ width: "100%" }}
                                        required
                                      />
                                    )}
                                  </InputMask>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Typography className={typographySubtitulo}>
                                    ENDEREÇO *
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    id={`clinicAddresses[${key}].street`}
                                    name={`clinicAddresses[${key}].street`}
                                    value={values?.clinicAddresses[key]?.street}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                      style: { fontSize: "12px" },
                                    }}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <Typography className={typographySubtitulo}>
                                    NÚMERO *
                                  </Typography>
                                  <NumberFormat
                                    customInput={TextField}
                                    fullWidth
                                    id={`clinicAddresses[${key}].number`}
                                    name={`clinicAddresses[${key}].number`}
                                    value={values?.clinicAddresses[key]?.number}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                      style: { fontSize: "12px" },
                                    }}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Typography className={typographySubtitulo}>
                                    BAIRRO *
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    id={`clinicAddresses[${key}].district`}
                                    name={`clinicAddresses[${key}].district`}
                                    value={
                                      values?.clinicAddresses[key]?.district
                                    }
                                    onChange={handleChange}
                                    InputLabelProps={{
                                      style: { fontSize: "12px" },
                                    }}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Typography className={typographySubtitulo}>
                                    COMPLEMENTO
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    id={`clinicAddresses[${key}].complement`}
                                    name={`clinicAddresses[${key}].complement`}
                                    value={
                                      values?.clinicAddresses[key]?.complement
                                    }
                                    onChange={handleChange}
                                    InputLabelProps={{
                                      style: { fontSize: "12px" },
                                    }}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Typography className={typographySubtitulo}>
                                    ESTADO *
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    id={`clinicAddresses[${key}].state`}
                                    name={`clinicAddresses[${key}].state`}
                                    value={values?.clinicAddresses[key]?.state}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                      style: { fontSize: "12px" },
                                    }} 
                                    required
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Typography className={typographySubtitulo}>
                                    CIDADE *
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    id={`clinicAddresses[${key}].city`}
                                    name={`clinicAddresses[${key}].city`}
                                    value={values?.clinicAddresses[key]?.city}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                      style: { fontSize: "12px" },
                                    }} 
                                    required
                                  />
                                </Grid>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    placeContent: "center",
                                  }}
                                >
                                    <Button
                              style={{
                                backgroundColor: "#ff5e8f",
                                borderColor: "#54B5CF",
                                borderRadius: "25px",
                                color: "#fff",
                                padding: "15px",
                                margin: "10px 0",
                                display: key >= 1 ? 'flex' : 'none',
                              }}
                              className={classes.botaoPrimeiro}
                              onClick={() =>
                                removeClinicHandler(
                                  values?.clinicAddresses[key].id
                                )
                              }
                            >
                              REMOVER CONSULTORIO 
                            </Button>
                                </div>
                              </Grid>
                            </Fragment>
                          ))}
                          {/* Fim */}
                        </Grid>
                        <Button
                          style={{
                            backgroundColor: "#54B5CF",
                            borderColor: "#54B5CF",
                            borderRadius: "25px",
                            color: "#fff",
                            padding: "15px",
                            margin: "20px 0",
                            display: 'none'
                          }}
                          className={classes.botaoPrimeiro}
                          onClick={addClinicHandler}
                          id="addConsulorio"
                        >
                          Adicionar Consultorio
                        </Button>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            <Typography className={typographySubtitulo}>
                              ACEITA CONVÊNIO?
                            </Typography>
                            <Typography className={subtitulo}>
                              Se sim, liste os convênios aceitos
                            </Typography>
                          </Grid>
                          {insurances
                            .sort(function (a, b) {
                              if (a.name < b.name) {
                                return -1;
                              }
                              if (a.name > b.name) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((data) => (
                              <Grid item key={data.id} xs={6} sm={3}>
                                <FormGroup>
                                  <StyledCheckbox
                                    checked={values?.insurance?.includes(
                                      data.id
                                    )}
                                    value={data.id}
                                    onChange={(e) =>
                                      handleChangeInsurances(e, data.name)
                                    }
                                    name="insurance"
                                    label={data.name}
                                    id={data.id}
                                  />
                                </FormGroup>
                              </Grid>
                            ))}
                        </Grid>
                        {values?.attendanceMethods?.map((data, index) => (
                          <Grid
                            container
                            spacing={3}
                            style={{
                              borderStyle: "outset",
                              borderColor: "#54B5CF",
                              BorderRadius: "5px",
                              borderRadius: "10px",
                              margin: "10px 0px",
                              padding: "10px 20px",
                            }}
                          >
                            <Grid item xs={12} sm={12}>
                              <Typography
                                className={typographySubtitulo}
                                style={{ textAlign: "center" }}
                              >
                                ATENDIMENTO {data.attendenceMethod.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                className={typographySubtitulo}
                                style={{ textAlign: "center" }}
                              >
                                VALOR (R$)
                              </Typography>
                              <TextField
                                fullWidth
                                id={`attendanceMethods[${index}].value`}
                                name={`attendanceMethods[${index}].value`}
                                value={values?.attendanceMethods[index]?.value}
                                onChange={handleChange}
                                InputLabelProps={{
                                  style: { fontSize: "12px" },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                className={typographySubtitulo}
                                style={{ textAlign: "center" }}
                              >
                                DURAÇÃO (Minutos)
                              </Typography>
                              <NumberFormat
                                customInput={TextField}
                                fullWidth
                                id={`attendanceMethods[${index}].schedule`}
                                name={`attendanceMethods[${index}].schedule`}
                                value={
                                  values?.attendanceMethods[index]?.schedule
                                }
                                onChange={handleChange}
                                InputLabelProps={{
                                  style: { fontSize: "12px" },
                                }}
                              />
                            </Grid>
                            <Grid
                              container
                              spacing={3}
                              style={{ justifyContent: "center" }}
                            >
                              <Grid item xs={12} sm={12}>
                                <Typography
                                  className={typographySubtitulo}
                                  style={{ textAlign: "center" }}
                                >
                                  HORÁRIOS DISPONÍVEIS
                                </Typography>
                              </Grid>

                              {[
                                { value: "Morning", label: "Manhã" },
                                { value: "Afternoon", label: "Tarde" },
                                { value: "Night", label: "Noite" },
                              ].map((el) => (
                                <Grid
                                  key={`${el.value}.${index}`}
                                  item
                                  xs={6}
                                  sm={3}
                                >
                                  <FormGroup>
                                    <StyledCheckbox
                                      checked={values?.attendanceMethods?.[
                                        index
                                      ]?.period?.includes(el.value)}
                                      id={`${el.value}.${index}`}
                                      name={`attendanceMethods[${index}].period`}
                                      onChange={handleChange}
                                      {...el}
                                    />
                                  </FormGroup>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        ))}

                        {values?.attendance?.map((atd, index) => {
                          return (
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12}>
                                <Typography className={typographySubtitulo}>
                                  ESPECIALIDADE PARA{" "}
                                  {attendenceMethod
                                    ?.find((data) => data.id === atd)
                                    ?.name?.toUpperCase() === "TELEATENDIMENTO"
                                    ? "TELEATENDIMENTOS"
                                    : "ATENDIMENTOS PRESENCIAIS"}
                                </Typography>
                              </Grid>
                              {specialities
                                .sort(function (a, b) {
                                  if (a.name < b.name) {
                                    return -1;
                                  }
                                  if (a.name > b.name) {
                                    return 1;
                                  }
                                  return 0;
                                })
                                // eslint-disable-next-line array-callback-return
                                .map((data, key) => {
                                  if (
                                    data.attendenceMethod?.id.toString() ===
                                    atd.toString()
                                  ) {
                                    return (
                                      <Grid item key={data.id} xs={6} sm={3}>
                                        <FormGroup>
                                          <StyledCheckbox
                                            checked={values?.speciality?.includes(
                                              data.id
                                            )}
                                            onChange={handleChangeSpecialities}
                                            value={data.id}
                                            name="speciality"
                                            label={data.name}
                                            id={`${data.id}.${index}.${key}`}
                                          />
                                        </FormGroup>
                                      </Grid>
                                    );
                                  } else {
                                  }
                                })}
                            </Grid>
                          );
                        })}

                      </Grid>

                      <Grid
                          style={{
                            textAlign: "center",
                            fontSize: 15,
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "24px",
                          }}
                        >
                          <button 
                            className={submit}
                            type="submit"
                            disabled={isSubmitting}
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "16px",
                              padding: "16px",
                              margin: "0",
                            }}
                          >
                            <p
                              style={{
                                margin: "0",
                              }}
                            >
                              SALVAR MEUS DADOS
                            </p>
                            {isSubmitting && <CircularProgress />}
                          </button>
                        </Grid>
                    </form>
                  )}
                </Formik>
                {/*   <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Voltar
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box> */}
              </DialogContentText>
            </DialogContent>
            {/*  <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions> */}
          </Dialog>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
