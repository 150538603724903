import React from "react";
import styled from "styled-components";
import Transposicao from "../../../assets/game_cartas_de_linguagem/transposicao.png";
import BackgroundImage from "../../../assets/game_cartas_de_linguagem/background.png";
import categoriasSemanticas from "../../../assets/game_cartas_de_linguagem/catogorias_semanticas.png";
import Classificacao from "../../../assets/game_cartas_de_linguagem/grupos.png";
import estouraBaloes from "../../../assets/game_cartas_de_linguagem/estoura_baloes.png";
import Memoria from "../../../assets/game_cartas_de_linguagem/memoria.png";
import Nomeacao from "../../../assets/game_cartas_de_linguagem/nomeacao.png";
import Logo from "../../../assets/game_cartas_de_linguagem/cartas_de_linguagem.png"
import { Link } from "react-router-dom";
import FooterLogoImage from "../../../assets/game_cartas_de_linguagem/profono.png";

const Container = styled.div`
  background-color: #ffcc33;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: Arial, sans-serif;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.9;
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const MenuItem = styled.img`
  width: 600px;
  margin: 0px 0;
  object-fit: none;
  height: 140px;
`;

const LogoImg = styled.img`
  position: absolute;
  top: -50px;
  left: -150px;
  width: 800px;
`;

const CustomMenuItem = styled(MenuItem)`
  width: 650px;
  height: 90px;
  object-fit: cover;
`;

const ClickableMenuItem = styled(MenuItem)`
  cursor: pointer;
`;

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;    
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px; 
`;

const FooterText = styled.div`
  font-size: 1.3rem;
  color: black;
  font-weight: bold;
`;

const FooterLogo = styled.img`
  width: 200px; 
  height: auto;
  max-width: fit-content;
  object-fit: contain;
`;

const Games = () => {
  return (
    <Container>
      <Background />
      <LogoImg src={Logo} alt="Logo" />
      <MenuList>
        <Link to="/admin/games/categorias">
          <ClickableMenuItem src={categoriasSemanticas} alt="Categorias Semânticas" />
        </Link>
        <Link to="/admin/games/categorias">
          <CustomMenuItem src={Classificacao} alt="Classificação em Grupos" />
        </Link>
        <Link to="/admin/games/categorias">
          <MenuItem src={estouraBaloes} alt="Estoura Balões" style={{ width: "280px", objectFit: "contain", height: "auto" }} />
        </Link>
        <Link to="/admin/games/memoria">
          <ClickableMenuItem src={Memoria} alt="Memória" style={{ width: "220px", objectFit: "contain", height: "auto" }} />
        </Link>
        <Link to="/admin/games/nomeacao">
          <ClickableMenuItem src={Nomeacao} alt="Nomeação" style={{ width: "230px", objectFit: "contain", height: "auto" }} />
        </Link>
        <Link to="/admin/games/transposicao">
          <ClickableMenuItem src={Transposicao} alt="Transposição" style={{ width: "300px", objectFit: "contain", height: "auto" }} />
        </Link>
      </MenuList>

      <FooterContainer>
        <FooterText>www.profono.com.br</FooterText>
        <FooterLogo src={FooterLogoImage} alt="Footer Logo" />
      </FooterContainer>
    </Container>
  );
};


export default Games;
