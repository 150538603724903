import React from 'react';
import { BackButtonWrapper } from './styles';
import BackIcon from '../../assets/game_cartas_de_linguagem/voltar.png'; 

const BackButton = () => {

  const handleBackClick = () => {
    window.history.back(); 
  };

  return (
    <BackButtonWrapper src={BackIcon} alt="Voltar" onClick={handleBackClick} />
  );
};

export default BackButton;
