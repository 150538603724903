import React from "react";
import styled from "styled-components";
import Transposicao from "../../../../assets/game_cartas_de_linguagem/transposicao.png";
import BackgroundImage from "../../../../assets/game_cartas_de_linguagem/background.png";
import Logo from "../../../../assets/game_cartas_de_linguagem/cartas_de_linguagem.png";
import BackButton from "../../../../components/back-button";
import FooterLogoImage from "../../../../assets/game_cartas_de_linguagem/profono.png";

const Container = styled.div`
  background-color: #ffcc33;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: Arial, sans-serif;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center;
  opacity: 0.9;
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const MenuItem = styled.img`
  width: 700px;
  margin: 0px 0;
  object-fit: none;
  height: 160px;
`;

const GameItem = styled.a`
  font-size: 24px;
  color: #a078c2;
  cursor: pointer;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;



const LogoImg = styled.img`
  position: absolute;
  top: -50px;
  left: -150px;
  width: 800px;
`;

const BackButtonContainer = styled.div`
  position: absolute;
  bottom: 90px; 
  left: 20px;
`;

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;    
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px; 
`;

const FooterText = styled.div`
  font-size: 1.3rem;
  color: black;
  font-weight: bold;
`;

const FooterLogo = styled.img`
  width: 200px; 
  height: auto;
  max-width: fit-content;
  object-fit: contain;
`;

const TransposicaoPage = () => {
  return (
    <Container>
      <Background />
      <LogoImg src={Logo} alt="Logo" />
      <MenuList>
        <MenuItem src={Transposicao} alt="Transposição" />
        <GameItem href="https://wordwall.net/play/81171/319/745" target="_blank" rel="noopener noreferrer">Jogo 1</GameItem>
        <GameItem href="https://wordwall.net/play/81700/131/285" target="_blank" rel="noopener noreferrer">Jogo 2</GameItem>
        <GameItem href="https://wordwall.net/play/82056/974/472" target="_blank" rel="noopener noreferrer">Jogo 3</GameItem>
        <GameItem href="https://wordwall.net/play/84833/167/758" target="_blank" rel="noopener noreferrer">Jogo 4</GameItem>
      </MenuList>

      <FooterContainer>
      <BackButtonContainer>
        <BackButton />
      </BackButtonContainer>
        <FooterText>www.profono.com.br</FooterText>
        <FooterLogo src={FooterLogoImage} alt="Footer Logo" />
      </FooterContainer>
    </Container>
  );
};

export default TransposicaoPage;
